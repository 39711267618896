.body-label {
   font-size: 20px;
}

.details-img {
   float: right;
   height: auto;
   margin-top: 5px;
   margin-right: 5px;
   max-width: 40px;
}

.history-options-container {
    display: inline-block;
    width: 100%;
}

.history-options-section {
    float: right;
    margin-right: 40%;
    min-height: 25px;
}

.history-options-subsection {
    float: right;
    margin-right: 20%;
    min-height: 25px;
}

.history-section {
   margin-bottom: 5px;
   width: 100%;
}

.subject-label {
   font-size: 24px;
}

.subsection { 
   width: 100%;
}

.timestamp-label {   
   font-size: 20px;
   font-weight: bold;
}