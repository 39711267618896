/* Please see documentation at https://docs.microsoft.com/aspnet/core/client-side/bundling-and-minification
for details on configuring this project to bundle and minify static web assets. */


/* change the background color */
.navbar-custom {
    background-color: #3d3433;
}

/* change the brand and text color */
.navbar-custom .navbar-brand,
.navbar-custom .navbar-text {
    color: rgba(255, 255, 255, .8);
}

/* change the link color */
.navbar-custom .navbar-nav .nav-link {
    color: #f7f3f2;
}

/* change the color of active or hovered links */
.navbar-custom .nav-item.active .nav-link,
.navbar-custom .nav-item:focus .nav-link,
.navbar-custom .nav-item:hover .nav-link {
    color: #ffffff;
}

.bg-metro-blue {
    background-color: #17498f;
}

.metro-orange-font {
    color: #ef7615;
}

a.navbar-brand {
    white-space: normal;
    text-align: center;
    word-break: break-all;
}

/* Sticky footer styles
-------------------------------------------------- */
html {
    font-size: 14px;
}

@media (min-width: 768px) {
    html {
        font-size: 16px;
    }
}

.action-img-sm {
    width: 50px;
    height: 50px;
    border: 2px solid;
    border-color: #3d3433;
    border-radius: 5px;
    margin-right: 10px;
}

.border-top {
    border-top: 1px solid #e5e5e5;
}

.border-bottom {
    border-bottom: 1px solid #e5e5e5;
}

.box-shadow {
    box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

button.accept-policy {
    font-size: 1rem;
    line-height: inherit;
}

/* Sticky footer styles
-------------------------------------------------- */
html {
    position: relative;
    min-height: 100%;
}

body {
    /* Margin bottom by footer height */
    /*margin-bottom: 60px;*/
    background-color: #f7f3f2;
}

.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    white-space: nowrap;
    /* Set the fixed height of the footer here */
    height: 60px;
    line-height: 60px; /* Vertically center the text there */
}

hr {
    background-color: white;
    border: 1px solid #3d3433;
}

tr:nth-child(even):not(.no-stripe) {
    background-color: #e0dbda;
}

tr:nth-child(even) .card-striped {
    background-color: #f0f0f0;
    border-radius: 15px;
}

tr:nth-child(odd) .card-striped {
    background-color: #f8f9fa;
    border-radius: 15px;
}


.property-label {
    font-size: 20px;
}

.value-label {
    font-size: large;
}

.action-btn {
    font-size: small;
    width: 100px;
    float: right;
}

.create-btn {
    font-size: medium;
    width: 125px;
    float: right;
    margin-top: 1%;
}

.announcement-header {
    font-size: x-large;
}

.announcement-detail {
    font-size: small;
}

.announcement-body {
    font-size: medium;
}

.announcement-image {
    width: 100%;
    height: 100%;
}

.header-div {
    width: 100%;
    float: left;
}

.editor {
    width: 350px;
    height: 30px;
    margin-left: 25px;
    border-radius: 5px;
    padding-left: 5px;
}

.action-img {
    width: 75px;
    height: 75px;
    border: 2px solid;
    border-color: #3d3433;
    border-radius: 5px;
}

a {
    color: #3d3433;
}

a:hover {
    color: #3d3433;
}

.icon-row {
    width: 80%;
    align-content: center;
    padding-left: 10%;
    height: 100px;
}

.location-icon-row {
    width: 40%;
    text-align: center;
    padding-left: 10%;
    height: 100px;
}

.red-text {
    color: #fa1414;
}

.green-text {
    color: #1ed115;
}

.groupbox {
    color: #3d3433;
    border: 2px solid;
    border-radius: 5px;
}

.groupbox-header {
    color: #3d3433;
    float: left;
    font-size: 26px;
    margin-left: 10px;
    margin-top: 5px;
}

.groupbox-header-sm {
    color: #3d3433;
    float: left;
    font-size: 20px;
    margin-left: 10px;
    margin-top: 5px;
}

.groupbox-btn {
    width: 100px;
    height: 30px;
    background-color: #4dbc3e;
    border-radius: 5px;
    float: right;
    text-align: center;
    margin-right: 10px;
    margin-top: 10px;
}

.groupbox-section {
    color: #3d3433;
    margin-left: 10px;
}

.groupbox-value {
    margin-left: 10px;
}

.groupbox-row-padding {
    height: 10px;
}

.groupbox-row-padding-lg {
    height: 50px;
}

.btn-label {
    font-size: 14px;
    color: white;
    margin-top: 4px;
}

.icon {
    text-align: center;
    float: left;
    padding-left: 40px;
}

.icon-label {
    text-align: center;
}

.avatar-large {
    height: 450px;
    width: 450px;
    border-radius: 50%;
}

.avatar-medium {
    height: 250px;
    width: 250px;
    border: 2px solid;
    border-radius: 50%;
    border-color: #3d3433;
    margin-bottom: 15px;
}

.avatar-small {
    height: 250px;
    width: 250px;
    border-radius: 50%;
}

.div-block {
    display: block;
    overflow: auto;
}

.div-block-right {
    display: block;
    overflow: auto;
    float: right;
}

.div-block-left {
    display: block;
    overflow: auto;
    float: left;
}

.div-inline {
    display: inline-flex;
}

.side-nav-menu {
    float: left;
    width: 25%;
    border-radius: 5px;
    text-align: center;
    background-color: #f7f3f2;
}

.side-nav-menu-btn {
    height: 40px;
    font-size: 20px;
    color: white;
    vertical-align: middle;
    line-height: 35px;
    border-color: #3d3433;
    border: 2px solid;
    background-color: #3d3433;
}

.selected-menu-btn {
    height: 40px;
    font-size: 20px;
    color: #3d3433;
    background-color: white;
    vertical-align: middle;
    line-height: 35px;
    border-color: #3d3433;
    border: 2px solid;
}

.account-subview {
    width: 60%;
    text-align: right;
    float: right;
    margin-right: 5%;
}

.menu-dropdown {
    background-color: #3d3433;
    color: #f7f3f2;
    border: none;
}

.dropdown-submenu {
    background-color: #3d3433;
    color: #ef7615;
    border: 2px solid;
    border-color: #f7f3f2;
}

.menu-dropdown-item {
    color: #f7f3f2;
}

.half-subview-right {
    width: 45%;
    text-align: right;
    float: right;
    margin-left: 5%;
    margin-top: 25px;
}

.half-subview-left {
    width: 45%;
    text-align: left;
    float: left;
    margin-right: 5%;
    margin-top: 25px;
}

.textbox-tall-default {
    width: 400px;
    margin-left: 15px;
    font-size: 14px;
    border-radius: 5px;
    height: 100px;
    font-family: inherit;
}

.provider-btn {
    background-color: #17498f;
    color: white;
    border-radius: 5px;
    font-size: 18px;
}

.date-picker {
    width: 400px;
    font-size: 14px;
    border-radius: 5px;
    font-family: inherit;
}

.shrink {

    transition: transform .2s; /* Animation */
}

.shrink:hover {
    transform: scale(.95);
    cursor: pointer;
}

.flip-card {
    background-color: transparent;
    perspective: 1000px; /* Remove this if you don't want the 3D effect */
}

/* This container is needed to position the front and back side */
.flip-card-inner {
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
}

/* Do an horizontal flip when you move the mouse over the flip box container */
.flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
}


/* Position the front and back side */
.flip-card-front, .flip-card-back {
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;
    transform: rotateX(0deg); /*Firefox*/
}

/* Style the front side (fallback if image is missing) */
.flip-card-front {
    color: black;
}

/* Style the back side */
.flip-card-back {
    text-decoration: none;
    transform: rotateY(180deg);
}