.header-action-img {
    width: 50px;
    height: auto;
    border: 2px solid;
    border-color: #3d3433;
    border-radius: 5px;
}

.header-container {
    width: 100%;
    display: inline-block;
}

.header-label {
    font-size: 32px;
    font-weight: bold;
}

.header-label-sm {
    font-size: 24px;
    font-weight: bold;
}

.header-section-left {
    width: 60%;
    float: left;
}

.header-section-right {
    width: 60%;
    float: right;
}
