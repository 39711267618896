/*Spacing*/

.spacing-bottom-15 {
    margin-bottom: 15px;
}

.spacing-bottom-25 {
    margin-bottom: 25px;
}

.margin-bottom-25px {
    margin-bottom: 25px;
}

.spacing-bottom-50 {
    margin-bottom: 50px;
}

.spacing-bottom-100 {
    margin-bottom: 100px;
}

.spacing-15 {
    margin-top: 15px;
}

.margin-top-15 {
    margin-top: 15px;
}

.spacing-top-15 {
    margin-top: 15px;
}

.spacing-top-25 {
    margin-top: 25px;
}

.margin-top-25 {
    margin-top: 25px;
}

.margin-top-25px {
    margin-top: 25px;
}

.spacing-top-50 {
    margin-top: 50px;
}

.margin-top-50px {
    margin-top: 50px;
}

.spacing-top-100 {
    margin-top: 100px;
}

.spacing-right-10 {
    margin-right: 10px;
}

.spacing-right-25 {
    margin-right: 25px;
}

.spacing-right-50 {
    margin-right: 50px;
}

.spacing-left-25 {
    margin-left: 25px;
}

.spacing-left-50 {
    margin-left: 50px;
}

.margin-left-5 {
    margin-left: 5%;
}

.margin-left-10 {
    margin-left: 10%;
}

.margin-right-5 {
    margin-right: 5%;
}

.margin-right-10 {
    margin-right: 10%;
}

.margin-right-15 {
    margin-right: 15%;
}

.margin-right-20 {
    margin-right: 20%;
}

.margin-right-25 {
    margin-right: 25%;
}
/**/
/*Font Styles*/
.bold {
    font-weight: bold;
}

.font-size-16 {
    font-size: 16px;
}

.font-size-20 {
    font-size: 20px;
}

.font-size-22 {
    font-size: 22px;
}

.font-size-24 {
    font-size: 24px;
}
/**/

/*Alignment*/
.text-center {
    text-align: center;
}

.centered {
    text-align: center;
}
.float-left {
    float: left;
}

.float-right {
    float: right;
}
.content {
    padding: 0 18px;
    background-color: #f1f1f1;
    margin-top: 5px;
}

.content-left {
    float: left;
    width: 50%;
}

.content-right {
    float: right;
    width: 50%;
}
/**/

/*Visibility*/
.hidden {
    visibility: collapse;
}

.visible {
    visibility: visible;
}
/**/