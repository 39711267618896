


/*  Collapsible element styling  */
.collapsible {
    background-color: #777;
    color: #f7f3f2;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 22px;
}

    

.centered-section {
    float: right;
    display: inline-flex;
    margin-right: 30%;
    margin-top: 10px;
}

.section-container-incident {
    display: inline-block;
}

.video-container {
    width: 100%;
    text-align: center;
    margin-top: 5%;
}

.video-player-med {
    max-width: 100%;
    max-height: 600px;
}

.video-player-tickets {
    max-width: 100%;
    max-height: 100vh;
}
