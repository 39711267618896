// Custom.scss
// Option A: Include all of Bootstrap

// Include any default variable overrides here (though functions won't be available)
$success: #1ac45e;
$danger: #9c413d;
$info: #4287f5;

$accordion-bg: #f1f1f1;
$accordion-button-bg: #777;
$accordion-button-color: #f7f3f2;
$accordion-button-active-bg: #3d3433;
$accordion-button-active-color: #f7f3f2;

$dropdown-dark-divider-bg: #e9ecef;
$dropdown-divider-bg: #e9ecef;

iframe {
    border: 1px solid black;
}

@import "node_modules/bootstrap/scss/bootstrap/";
@import '@splidejs/splide/css';


// Then add additional custom code here