.feed-body-label {
    font-size: 18px;
}

.feed-dismiss-label {
    color: #9c413d;
    float: right;
    font-style: oblique;
    font-weight: bold;
    margin-right: 10px;
}

.feed-header {
    width: 60%;
    float: right;
}

.feed-header-label {
    font-size: 32px;
    font-weight: bold;
}

.feed-header-container {
    display: inline-block;
    width: 100%;
}

.feed-item-container {
    margin-top: 5px;
}

.feed-sep {
    background-color: gray;
    margin-bottom: -2px;
}

.feed-subject-label {
    font-size: 20px;
    font-weight: bold;
}

.feed-timestamp-label {
    font-size: 20px;
    font-weight: bolder;
    vertical-align: top;
}

.feed-type-img {
    max-width: 35px;
    height: auto;
}
