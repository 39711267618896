.external-iframe {
    width: 100%;
    height: 90vh;
    border-radius: 25px;
}
.pdf-viewer {
    width: 100%;
    height: 100vh;
    border-radius: 25px;
}

.iframe-action-btn {
    min-width: 125px;
    height: 40px;
    border-radius: 5px;
    font-size: 16px;
    border-color: #3d3433;
}

.iframe-container {
    background-color: #f7f3f2;
}

.account-iframe {
    width: 100%;
    height: 500px;
    border-radius: 5px;
}

.full-iframe{
    width: 100%;
    height:50vh;
    border: 0;
}

.iframe-loading {
    background: url(../../../wwwroot/Images/Gifs/loading-with-message.gif) center center no-repeat;
    background-color: #f7f3f2;
}

.subview-iframe {
    width: 100%;
    height: 400px;
    border-radius: 5px;
}

.feed-iframe {
    height: 40vh;
    border-radius: 5px;
}