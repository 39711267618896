.subview-container {
   text-align: right;
   display: block;
   overflow: auto;
   margin-right: 10%;
}

.container-section {
   margin-top: 10px;
   display: inline-flex;
}

.textbox {
   width: 400px;
   margin-left: 15px;
   font-size: 14px;
   border-radius: 5px;
   height: 25px;
}

.action-btn-subview {
   height: 30px;
   width: 125px;
   border-radius: 5px;
   color: white;
   background-color: #38a832;
}

.back-btn {
   background-color: gray;
}

.delete-btn {
   background-color: #bf3106;
}

.details-btn {
   background-color: #002fff;
   margin-right: 10px;
}

/*     SLIDER SWITCH     */
/* The switch - the box around the slider */
.switch-lg {
   position: relative;
   display: inline-block;
   width: 100px;
   height: 34px;
}

   /* Hide default HTML checkbox */
.switch-lg input {
   opacity: 0;
   width: 0;
   height: 0;
}

/* The slider */
.slider-lg {
   position: absolute;
   cursor: pointer;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   background-color: #ccc;
   -webkit-transition: .5s;
   transition: .5s;
}

.slider-lg:before {
   position: absolute;
   content: "";
   height: 26px;
   width: 26px;
   left: 4px;
   bottom: 4px;
   background-color: white;
   -webkit-transition: .5s;
   transition: .5s;
}

input:checked + .slider-lg {
    background-color: #2196F3;
}

input:focus + .slider-lg {
    box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider-lg:before {
   -webkit-transform: translateX(66px);
   -ms-transform: translateX(66px);
   transform: translateX(66px);
}

/* Rounded sliders */
.slider-lg.round {
    border-radius: 34px;
}

.slider-lg.round:before {
    border-radius: 50%;
}

/*     END SLIDER SWITCH     */
