iframe {
    border: 1px solid black;
}

hr {
   width: 100%;
}

.btn-back {
   background-color: #3d3433;
   color: #f7f3f2;
}

.btn-blue {
   background-color: #4287f5;
   color: #f7f3f2;
}

.btn-green {
   background-color: #1ac45e;
   color: white;
}
.error-msg {
   font-size: 22px;
   font-style: oblique;
   color: red;
}

.normal-msg {
   font-size: 22px;
   font-style: oblique;
}

.success-msg {
   font-size: 22px;
   font-style: oblique;
   color: green;
}

.orange-svg{
   fill: rgb(243, 116, 38);
}