.btn:not(.btn-outline-info):not(.btn-outline-success):not(.btn-outline-danger):not(.btn-outline-primary):not(.btn-outline-secondary):not(.btn-outline-warning):not(.btn-outline-light):not(.btn-outline-dark):not(.btn-light){
    color: white;
}

.btn-outline-info:hover{
   color: white;
}

textarea {
}

.primary-color {
   background-color: #f7f3f2;
}

.secondary-color {
   background-color: #3d3433;
}

.btn-med {
   width: 125px;
   height: 40px;
   border-radius: 5px;
   font-size: 16px;
   border-color: #3d3433;
}

.btn-lrg {
   width: 150px;
   height: 50px;
   border-radius: 5px;
   font-size: 20px;
   border-color: #3d3433;
}

.btn-wide {
   width: 150px;
}

.btn-row-container {
   text-align: center;
}

.btn-red {
   background-color: #9c413d;
   color: #f7f3f2;
}

.file-upload {
   width: 300px;
   margin-left: 15px;
   font-size: 14px;
   height: 25px;
}

.row-action-img {
   height: 30px;
   width: 30px;
   margin-left: 3px;
   margin-right: 3px;
}

.row-info-img {
   height: 30px;
   width: 30px;
}

.image-label {
   font-size: 14px;
   font-style: oblique;
}

.image-link {
   text-align: center;
}

.input-form {
   display: inline-block;
}

.input-body {
   float: right;
}

.item-img-sm {
   max-width: 75px;
   height: auto;
   border: 1px solid;
   border-color: #3d3433;
   border-radius: 5px;
}

.item-img-med {
   max-width: 150px;
   height: auto;
   border: 2px solid;
   border-color: #3d3433;
   border-radius: 5px;
}

.item-img-lrg {
   max-width: 300px;
   height: auto;
   border: 2px solid;
   border-color: #3d3433;
   border-radius: 5px;
}

.item-row-action {
   height: 25px;
   width: 25px;
}

.item-row {
   /*height: 100%;*/
}

.item-row-med {
   height: 70px;
}

.leaderboard-rank-label {
   font-size: 38px;
   font-weight: bold;
}

.login-tab-btn {
   width: 300px;
   height: 50px;
   border-radius: 5px;
   font-size: 20px;
   border-color: #3d3433;
   float: right;
}

.login-tab-header {
   font-size: 34px;
   margin-bottom: 50px;
   font-weight: bold;
   margin-left: 25%;
}

.login-tab-textbox {
   width: 300px;
}

.msg-body {
   font-size: 26px;
   font-style: oblique;
   text-align: center;
}

.readonly {
   background-color: lightgray;
}

.section {
   min-height: 35px;
   float: right;
}

.section-centered-img {
   text-align: center;
   display: block;
   overflow: auto;
}

.section-container {
   width: 100%;
   display: inline-block;
}

.section-left {
   width: 25%;
   min-height: 50px;
   float: left;
   border: 2px solid;
   border-color: #3d3433;
   border-radius: 5px;
}

.section-right {
   width: 75%;
   float: right;
   height: 300px;
   background-color: blue;
}

.size-row-btn {
   height: 30px;
   width: 90%;
   float: right;
   border-radius: 3px;
}

.size-column {
   width: 20%;
}

.size-row-label {
   font-size: 24px;
}

.size-row {
   height: 30px;
}

.size-row-edit {
   margin-bottom: 25px;
}

.size-row-img {
   max-width: 30px;
   height: auto;
   margin-left: 15px;
}

.size-row-label {
   font-size: 24px;
}

.size-textbox {
   width: 90%;
   height: 30px;
   font-size: 16px;
}

.slider-container { 
   display: inline-flex;
}

.slider-container-center {
   display: inline-flex;
   margin-left: 33%;
}

.slider-label {
   font-size: 24px;
   font-weight: bold;
   margin-right: 25px;
}

.slider-section {
   min-height: 50px;
}

.subheader-label {
   font-size: 26px;
   font-weight: bold;
}

.subview-body {
    margin: 8px;
    background: #f7f3f2;
    color: #3d3433;
}

.subview-title-div {
   display: inline-block;
}

.subview-property-section {
   display: block;
   font-size: 18px;
   margin-top: 3px;
   width: 100%;
}

.tab-body {
   display: block;
   width: 400px;
}

.tab-input-section {
   display: block;
   font-size: 18px;
   float: right;
   height: 50px;
}

.tab-input-row {
   min-height: 50px;
}

.tab-label {
   font-size: 20px;
   font-weight: bold;
}

.textbox-label {
   font-size: 20px;
   font-weight: bold;
}

.textbox-sm {
    width: 200px;
    height: 30px;
    border-radius: 5px;
}

.textbox-med {
   width: 300px;
   margin-left: 15px;
   font-size: 14px;
   border-radius: 5px;
   height: 25px;
}

.textbox-med-label {
    font-size: 24px;
    font-weight: bold;
    margin-right: 20px;
    margin-top: -2px;
}

.textbox-tall {
   height: 100px;
}

.textbox-tall-incident {
   height: 75px;
}

.user-allowance-label {
   font-size: 18px;
}

.user-img-med {
   max-width: 150px;
   height: auto;
   border: 2px solid;
   border-radius: 5%;
   border-color: #3d3433;
}

.user-img-sm {
   max-width: 75px;
   height: auto;
   border: 1px solid;
   border-radius: 5%;
   border-color: #3d3433;
}

.user-name-label {
   font-size: 22px;
   font-weight: bold;
}
