.branded-background{
    background-image: url("../assets/Blue Diamonds.jpg");
    background-size: cover;
    background-position: center;
}

.branded{
    color: rgb(204,204,204);
}

.metro-orange-btn{
    color: rgb(204, 204, 204);
    background-color: rgb(243, 116, 38);
    border: rgb(243,116,38);
}